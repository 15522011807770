html,
body,
#root {
  height: 100%;
  font-size: 14px;
}
.body {
  background-color: #c2c2c2;
  font-family: "SegoeUIRegular,Courier,monospace !important";

  label,
  p,
  span,
  td,
  th {
    font-family: "SegoeUIRegular,Courier,monospace" !important;
  }
}
.link-btn {
  color: #2f80ed;
  text-decoration: none;
  cursor: pointer;
}
.full-page-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1366;
}
.spinner-loader {
  width: 150px !important;
  height: 150px !important;
  position: absolute;
  top: 40%;
  left: 45%;
}
.grey-back {
  background-color: #c2c2c2;
}
.white-back {
  background-color: #ffffff;
}
.login {
  padding: 3rem !important;
  border-radius: 4px;
  width: 520px !important;
  max-width: 520px !important;
  height: max-content;
  padding-top: 10px !important;
}
.grey-text-small {
  color: #7e7e7e;
  font-size: 12px;
}
.App-header {
  background-color: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 1px -1px 5px black;
  width: 100%;
  min-height: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: 000000;
}
.nav-group {
  color: black !important;
}
.account-img {
  width: 331px;
}

.App-link {
  color: #61dafb;
}
.dropdownLink {
  width: 100% !important;
  display: block;
}
body {
  overflow-x: hidden;
}
.dropdown-item.active,
.dropdown-item:active {
  color: #2f80ed !important;
  background-color: transparent !important;
}

/* Toggle Styles */
.dash-container {
  height: 100%;
}
.dash-home {
  .text-muted {
    text-decoration: none;
  }
}
.topNav {
  .navbar-brand {
    color: #707070;
  }
  .navbar-nav {
    a {
      color: #707070;
      margin-right: 30px;
      text-decoration: none;
      font-size: 14px;
      //width: 100px;
    }
    a.active {
      // text-decoration: underline;
      color: #2f80ed;
      font-weight: 600;
    }
  }
  .nav-link-top {
    a {
      padding: 0 !important;
    }
    // margin-right: 30px;
  }
  a.dropdown-item {
    padding: 0.8rem !important;
  }
}
/* Sidebar Styles */
.right-side {
  // background-color: #f2f2f2;
  height: max-content;
  min-height: 100%;
}

.reusable-table {
  font-size: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  th {
    color: #2f83c5 !important;
    background-color: #fbfbfb !important;
    padding: 0.5em;
    // width: 150px;

    display: table-cell !important;
    text-align: center;
    vertical-align: middle;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    white-space: nowrap;
    input {
      border: 1px solid #d8d6d6;
      outline: none;
      border-radius: 5px;
      width: 100%;
    }
  }
  td {
    color: #333;
    border: 1px solid #d8d6d6;
    padding: 0.8rem 0.9rem;
    // width: 150px;
    max-width: 10vw;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    .table-img {
      width: 100px;
      height: 100px;
      object-fit: contain;
      // border-radius: 50%;
    }
  }
  .initial-sort-icon {
    font-weight: 100;
  }
  .sr-column {
    text-align: center;
  }
}

// .service-list-table {
//   height: 250px !important;
//   max-height: 250px;
// }
.add-service-modal {
  width: 70vw;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.container {
  max-width: 100% !important;
}
.wrap {
  white-space: nowrap;
}
.modal-image {
  width: 60% !important;
  max-width: 900px !important;
  img {
    width: 100%;
  }
}
.profile-button {
  color: #707070;
  border-color: #707070;
  background: transparent !important;
  border-radius: 50% !important;
  width: 50px;
  height: 50px;
  .profile {
    // margin-left:2px;
    font-size: 25px;
    font-weight: 600;
    color: #707070;
    text-decoration: none !important;
  }
  margin-right: 50px;
}
.profile-button::after {
  display: none !important;
}
.service-question {
  .column-name {
    margin-bottom: 1rem;
    label {
      font-size: 1.2rem;
      font-weight: 600;
      margin-right: 10px;
    }
  }
  width: 80%;
  margin: auto;
}

.col-9,
.col-10 {
  padding-top: 5px;
}

.service-column {
  label {
    margin-left: 10px;
  }
}
.edit-btn {
  margin-right: 10px;
}
.options {
  margin-right: 10px;
}

.accordion-button {
  pointer-events: none;
}

.accordion-button::after {
  pointer-events: all;
}

.accordion-button .options a {
  pointer-events: all;
}

.navbar {
  z-index: 1000; /* Adjust the value based on your layout */
}

.location-card-row .col {
  padding: 2px;
}

.deactivated-text {
  color: #444;
  opacity: 0.5;
  // text-decoration: line-through;
}
.cursor-change {
  cursor: pointer;
}

.province-name-validate {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.otp-input {
  height: 30px !important;
}

.input-otp {
  height: 30px !important;
  gap: 35px !important;
}

.view-skill-trade {
  max-width: 80% !important;
}
